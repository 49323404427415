.reviews-summary {
    
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 20px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.rev-star {
    color: #56c056;
}

th {
    color: #56c056;
}


.single-course-btn-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.single-course-modal-btn {
    font-size: 14pt;
    padding: 10px;
    border-radius: 20px;
    background-color: darkgreen;
    color: white;
    width: 150px;
}


.user-review {
   
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.reviewer-name {
    margin-top: 5px;
    margin-bottom: 5px;
}

.num-stars {
    margin-top: 1px;
}

.edit-del-div {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 70%;
    gap: 20px;
}

.del-edit-rev-btn {
    height: 25px;
    background-color: darkgreen;
    color: white;
    border-radius: 15px;
}

.review-container {
    display: flex;
    flex-direction: column-reverse;
}

b {
    font-size: 14pt;
}


