.favorites-map {
    margin-top: 30px;
    z-index: -1;
}
.favorites-div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100%;
}

.empty-favorites {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.favorites-container {
    height: 94vh;
    border-top: 2px solid lightgray;
    margin-top: 10px;
}

.fav-delete-btn {
    padding: 5px;
    background-color: darkgreen;
    color: white;
    border-radius: 15px;
    width: 100%;
}

.fav-course-section {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}