.completed-course-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 20px;
}

.completed-course-container {
    border: 2px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 50%;
    background-color: whitesmoke;
}

.edit-delete-completed-btn {
    background-color: darkgreen;
    color: white;
    padding: 5px;
    border-radius: 15px;
    width: 15%;
    margin-right: 10px;
}

.player-info-div {
    white-space: -moz-pre-wrap; /* Mozilla */
    white-space: -hp-pre-wrap; /* HP printers */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
    word-break: break-all;
}
