.sign-form-container {
    display: flex;
    justify-content: center;
    
}

.sign-form-div {
    padding: 20px;
    width: 270px;
}

.sign-form-form {
    display: flex;
    flex-direction: column;
}

.sign-form-header {
    font-size: 30px;
    font-weight: bold;
    margin-top: 15px;
}

.sign-form-section-div {
    margin: 7px 0px;
}

.sign-form-input {
    width: 260px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.sign-button-div {
    margin: 20px 0px;
}

.sign-form-button {
    width: 266px;
    height: 30px;
    border-radius: 15px;
    background-color: darkgreen;
    color: white;
}

.sign-form-button:hover {
    font-weight: bold;
}