.single-page-div {
    width: 100%;
    height: 100vh;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    background-color: whitesmoke;
}

.single-course-info {
    display: flex;
    width: 80%;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 2px 2px 2px 2px darkgray;
    background-color: white;
    margin-top: 20px;
}

.img-overlay {
    height: 0px;
}

.single-course-images {
    border-radius: 20px;
    
}

.single-course-images img {
    object-fit: cover;
    min-height: 200px;
    height: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.course-info-p {
    position: relative;
    bottom: 150px;
    font-size: 18pt;
    color: #efefec;
    font-weight: bolder;
    text-shadow: black 1px 1px 5px;
    margin:10px;
}

.single-course-details {
    display: flex;
    gap: 50px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 15px;
    min-height: 250px;
    margin-left: 15px;
}

.single-course-desc {
    border-bottom: 1px solid lightgray;
    margin-left: 15px;
    white-space: -moz-pre-wrap; /* Mozilla */
    white-space: -hp-pre-wrap; /* HP printers */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
    word-break: break-all;
}

.type-div {
    width: 15%;
}

.reviews-div {
    margin-left: 15px;
}

.url-div {
    width: 50%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    
}

strong {
    font-size: 14pt;
}

a {
    color: darkgreen;
    font-size: 14pt;
}

p {
    font-size: 14pt;
}

