.modals-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-btn {
    height: 30px;
    background-color: darkgreen;
    color: white;
    border-radius: 20px;
}

.modal-keep-btn {
    height: 30px;
    background-color: white;
    border-radius: 20px;
}

.delete-rev-btn-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}