.star__container {
    display: flex;
    align-items: center;
}

.star__text {
    font-size: 14pt;
}

.rate {
    float: left;
    height: 46px;
    
}

.rate:not(:checked)>input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked)>label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
}

.rate:not(:checked)>label:before {
    content: '★ ';
}

.rate>input:checked~label {
    color: #56c056;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
    color: #56c056;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
    color: #56c056;
}

.rev-btn-div {
    border-top: 1.5px solid lightgray;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}

.submit-rev-btn {
    margin-top: 25px;
    height: 40px;
    width: 100px;
    background-color: darkgreen;
    color: white;
    border-radius: 20px;
}

ul {
    padding: 0;
}