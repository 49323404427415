.completed-course-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

ul {
    list-style: none;
    color: red;
}

.completed-course-form > * {
    margin-bottom: 10px;
}

.completed-score {
    width: 93%;
}

.completed-score > p,
.completed-feedback > p {
    font-weight: bold;
    margin-bottom: 5px;
}

.completed-score > input[type="number"],
.completed-feedback > textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.completed-feedback > textarea {
    resize: vertical;
}

.sign-form-button {
    background-color: darkgreen;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

