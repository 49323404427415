.user-course-card img {
    transition: transform 0.4s ease-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
} 

.user-card-div {
    width: 350px;
    height: 350px;
}

.user-course-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    margin: auto;
    place-items: center;
    row-gap: 10px;
    column-gap: 10px;
    margin: 8px;
    padding: 20px 0;
}

.user-course-container .user-course-card {
    aspect-ratio: 4/3;
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.2);
}

.user-course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-course-name {
    color: darkgreen;
    font-weight: bold;
}

.user-course-btns {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

.edit-delete-user-course {
    width: 55px;
    height: 30px;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 15px;
    box-shadow: 1px 1px 1px 1px lightgray;
}

button:hover {
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
}

.home-img-div {
    max-height: 350px;
}

.home-img-div img {
    width: 100%;
    object-fit: cover;
    max-height: 350px;
}

.edit-photo {
    position: relative;
    bottom: 30px;
    box-shadow: none;
}



/* css for delete course */

.delete-course-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.delete-button {
    width: 50%;
    background-color: darkgreen;
    color: white;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 2px 2px 2px 2px lightgray;
    font-size: 14pt;
}

.no-user-course-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-add-course {
    font-weight: bold;
    text-decoration: none;
    border: 1px solid lightgray;
    padding: 15px;
    background-color: darkgreen;
    color: white;
    border-radius: 25px;
}





