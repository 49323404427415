.create-form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
}

.course-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 2px 2px lightgray;
    border-radius: 15px;
    padding: 30px;
    justify-content: center;
    align-items: center;
}

.form-section-div {
    width: 100%;
}

.form-input {
    width: 100%;
}

.form-section {
    padding: 3px;
}

.sign-button-div {
    display: flex;
    justify-content: center;
}